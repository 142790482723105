.home {
  .slideshow {
    overflow: hidden;
    height: 540px;
    
    .slide {
      height: 540px;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;
      position: relative;
      outline: none;
      
      &:focus,
      &:active,
      &:focus:active {
        outline: none;
      }
      
      .slide-link {
        position: absolute;
        z-index: 5;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-decoration: none;
      }
      
      .slide-content {
        bottom: 100%;
        left: 0;
        right: 0;
        position: absolute;
        opacity: .5;
        transition: all .3s ease-in-out;
        
        .slide-title {
          > span {
            display: inline-block;
            font-size: 32px;
            color: #fff;
            background: rgba(0, 0, 0, .74);
            line-height: 42px;
            padding: 3px 20px 0;
            vertical-align: top;
            box-shadow: 1px 2px 2px rgba(0,0,0,.4);
            max-width: 480px;
          }
          
          margin: 0 0 4px;
        }
        
        .slide-text {
          background: rgba(255, 255, 255, .8);
          box-shadow: 2px 3px 4px rgba(0,0,0,.43);
          color: #ba122b;
          font-size: 20px;
          max-width: 400px;
          padding: 20px 25px;
        }
      }
      
      &.slick-active {
        .slide-content {
          bottom: 185px;
          opacity: 1;
          transition-delay: .1s;
        }
      }
    }
  }
  
  .homepage-buckets {
    margin: 0 -30px;
    padding: 45px 0 25px;
    position: relative;
    @include clearfix;
    
    > div {
      float: left;
      width: 25%;
      padding: 15px 30px;
      position: relative;
      text-align: center;
      
      .icon {
        height: 80px;
        margin: 0 0 10px;
        
        img {
          margin: auto;
        }
      }
      
      h3 {
        color: $brand-primary;
      }
      
      .ghost-link {
        position: absolute;
        text-decoration: none;
        font-size: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      
      &.with-link {
        &:hover {
          color: $brand-primary;
        }
      }
    }
    
    > span {
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 0;
      border-top: solid 1px #636363;
      border-bottom: solid 1px #fff;
      font-size: 0;
      height: 2px;
    }
    
    @media (max-width: $screen-sm-max) {
      margin: 0 -15px;
      
      > div {
        padding: 15px;
      }
    }
    
    @media (max-width: $screen-xs) {
      margin: 0 -15px;
      
      > div {
        float: none;
        width: auto;
        max-width: 360px;
        margin: auto;
        
        & + div {
          margin-top: 15px;
        }
      }
    }
  }
  
  .homepage-text {
    position: relative;
    padding: 50px 0 45px;
    
    &.with-image {
      .text-column {
        width: 56%;
        position: relative;
        z-index: 5;
      }
    }
    
    .image-column {
      position: absolute;
      right: -30px;
      bottom: 0;
      width: 45%;
      z-index: 0;
    }
    
    @media (max-width: $screen-sm-max) {
      padding-bottom: 0;
      
      &.with-image {
        .text-column {
          width: auto;
        }
      }
      
      .image-column {
        position: relative;
        right: 0;
        padding: 30px 0 0;
        margin: auto;
        max-width: 400px;
        width: auto;
        
        img {
          margin: auto;
        }
      }
    }
  }
  
  @media (max-width: $screen-xs-max) {
    .slideshow {
      height: 380px;
      
      .slide {
        height: 380px;
        
        .slide-content {
          .slide-title {
            > span {
              font-size: 26px;
              line-height: 36px;
            }
          }
          
          .slide-text {
            font-size: 18px;
            padding: 15px 20px;
          }
        }
        
        &.slick-active {
          .slide-content {
            bottom: 90px;
          }
        }
      }
    }
  }
}

.page-template-template-about {
  .intro-text {
    padding: 0 0 50px;
    
    &:empty {
      display: none;
      padding: 0;
    }
  }
  
  .team-member-row {
    & + .team-member-row {
      margin-top: 30px;
    }
  }
  
  .content-about {
    padding: 15px 0;
  }
  
  .side-image {
    float: left;
    width: 50%;
    padding: 15px;
    
    img {
      box-shadow: 1px 2px 3px rgba(0,0,0,.2);
      background: #fff;
    }
    
    & + .content-about {
      float: left;
      width: 50%;
      padding: 15px;
    }
  }
  
  .contact-info {
    display: table;
    width: 100%;
    padding: 20px 0;
    margin: 25px 0 35px;
    border-top: solid 1px #c2c2c2;
    border-bottom: solid 1px #c2c2c2;
    
    > div {
      display: table-row;
      
      > div {
        text-align: center;
        padding: 5px;
        display: table-cell;
        color: #cb0802;

        &:first-child {
          text-align: left;
          padding-left: 0;
        }

        &:last-child {
          text-align: right;
          padding-right: 0;
        }
        
        a {
          color: $text-color;
        }
      }
    }
  }
  
  .social-links-wrapper {
    text-align: center;
    
    a {
      display: inline-block;
      color: #f5f5f5;
      background: $brand-primary;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      text-decoration: none;
      font-size: 16px;
      border-radius: 16px;
      margin: 0 5px;
      transition: all .3s ease-in-out;
      
      i {
        line-height: 32px;
      }
      
      &:hover {
        background: lighten($brand-primary, 15%);
      }
    }
  }
  
  @media (max-width: $screen-xs-max) {
    .side-image {
      float: none;
      width: auto;
      
      & + .content-about {
        float: none;
        width: auto;
      }
    }
  }
  
  @media (max-width: $screen-xs) {
    .contact-info {
      > div {
        > div {
          width: 100%;
          display: block;
          text-align: center !important;
        }
      }
    }
  }
}

.page-template-template-contact {
  .contact-text {
    max-width: 800px;
    margin: auto;
    padding-top: 10px;
  }
  
  .contact-info {
    text-align: center;
    margin-top: 35px;
    border-top: solid 1px #959595;
    padding: 55px 0 10px;
    
    > div {
      display: inline-block;
      vertical-align: top;
      margin: 0 15px 15px;
      color: $brand-primary;
      font-size: 18px;
      position: relative;
      padding-left: 60px;
      height: 50px;
      
      > span {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        text-align: center;
        border: solid 1px $brand-primary;
        border-radius: 50%;
        
        .fa {
          font-size: 20px;
          line-height: 48px;
          
          &.fa-mobile-phone {
            font-size: 30px;
          }
        }
      }
      
      > a {
        display: inline-block;
        line-height: 30px;
        padding: 10px 0;
      }
    }
  }
  
  .bottom-contact-row {
    @include grey-dots-gradient(#e3e3e3, #e7e7e7, 0, .16);
    padding: 40px 0;
    
    .row {
      margin: 0 -20px;
    }
    
    .column-contact-form {
      float: left;
      width: 32.5%;
      padding: 0 20px;
      
      .column-wrapper {
        background: #fff;
        box-shadow: 1px 2px 3px rgba(0,0,0,.2);
        padding: 30px 40px 20px;
        min-height: 295px;
        color: $brand-primary;
        
        #{$headings} {
          color: $brand-primary;
        }
        
        h3 {
          font-size: 18px;
        }
      }
    }
    
    .map-column {
      width: 67.5%;
      float: left;
      padding: 0 20px;
      
      .column-wrapper {
        position: relative;
        background: #fff;
        box-shadow: 1px 2px 3px rgba(0,0,0,.2);
        height: 295px;
        
        .map-wrapper {
          width: 100%;
          height: 100%;
        }
        
        .map-overlay {
          position: absolute;
          z-index: 10;
          width: 100%;
          left: 0;
          bottom: 0;
          background: rgba(255, 255, 255, .72);
          padding: 20px 35px;
          font-size: 18px;
          
          strong, b {
            font-weight: 600;
          }
        }
      }
    }
  }
  
  @media (max-width: $screen-sm-max) {
    .bottom-contact-row {
      .row {
        margin: 0 -15px;
      }
      
      .column-contact-form {
        padding: 0 15px;
        width: 40%;
        
        .column-wrapper {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      
      .map-column {
        width: 60%;
        padding: 0 15px;
        
        .column-wrapper {
          .map-overlay {
            padding: 15px;
            font-size: 16px;
          }
        }
      }
    }
  }
  
  @media (max-width: $screen-xs-max) {
    .contact-info {
      > div {
        display: block;
        text-align: left;
      }
    }
    
    .bottom-contact-row {
      .row {
        margin: 0 -5px;
      }
      
      .column-contact-form,
      .map-column {
        float: none;
        width: auto;
      }
      
      .column-contact-form {
        margin-bottom: 30px;
      }
    }
  }
}

.page-template-template-properties {
  #properties-map {
    top: auto;
    left: auto;
    height: 640px;
    width: 100%;
    overflow: hidden;
    position: relative;
    
    .map-wrapper {
      width: 100%;
      height: 100%;
    }
  }
  
  .properties-wrapper {
    padding: 25px 0;
    
    .property-item {
      position: relative;
      background: #fff;
      box-shadow: 1px 2px 3px rgba(0,0,0,.2);
      height: 213px;
      padding-left: 310px;
      margin: 0 0 25px;
      transition: all .3s ease-in-out;
      @include clearfix;
      
      &:hover {
        background: #fcfcfc;
        box-shadow: 1px 2px 10px rgba(0,0,0,.5);
      }
      
      .property-image {
        position: absolute;
        width: 310px;
        height: 100%;
        left: 0;
        top: 0;
        background: no-repeat center;
        background-size: cover;
        overflow: hidden;
        
        img {
          width: 100%;
          height: auto;
        }
      }
      
      .property-top-info {
        margin: 0 0 20px;
        position: relative;
        background: #ebebeb;
        padding-right: 200px;
        
        .property-name {
          color: #363636;
          font-size: 22px;
          line-height: 35px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 10px 10px 10px 30px;
        }
        
        .property-price {
          width: 200px;
          text-align: center;
          line-height: 35px;
          padding: 10px;
          color: $brand-primary;
          font-size: 22px;
          font-weight: 600;
          background: #cecece;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      
      .property-detail-info {
        position: relative;
        padding: 0 10px;
        line-height: 1.4;
        @include clearfix;
        
        p {
          line-height: 1.4;
        }
        
        ul {
          display: block;
          margin: 0;
          padding: 0;
          list-style: none;
          
          li {
            position: relative;
            color: #636363;
            padding: 0 0 5px 20px;
            
            &:before {
              font-family: FontAwesome;
              content: $fa-var-angle-right;
              position: absolute;
              left: 0;
              top: 0;
            }
            
            strong {
              display: block;
              max-width: 100%;
              font-weight: 600;
              color: #000;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        
        > div {
          padding: 0 20px;
          
          &.beds-baths {
            ul {
              max-width: 150px;
              
              li {
                > span {
                  padding-right: 30px;
                }
                
                > strong {
                  position: absolute;
                  top: 0;
                  right: 30px;
                }
              }
            }
          }
        }
        
        .property-description {
          max-height: 60px;
          overflow: hidden;
          margin: 10px 0 0;
        }
      }
      
      .ghost-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        text-decoration: none;
        font-size: 0;
      }
    }
  }
  
  .results {
    position: relative;
    
    .loading-overlay {
      @include loading-overlay;
    }
  }
  
  @media (max-width: $screen-sm-max) {
    .properties-wrapper {
      .property-item {
        padding: 0;
        height: auto;
        
        .property-image {
          position: relative;
          height: 200px;
          float: left;
        }
        
        .property-top-info {
          padding: 0;
          margin-left: 310px;
          
          .property-price {
            width: auto;
            position: relative;
            right: auto;
            top: auto;
          }
        }
        
        .property-detail-info {
          clear: both;
          border-top: solid 1px #c2c2c2;
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
  
  @media (max-width: $screen-xs-max) {
    .properties-wrapper {
      .property-item {
        .property-image {
          position: relative;
          float: none;
          width: auto;
          text-align: center;
          margin: auto;
          height: 260px;
          max-width: 400px;
          
          img {
            margin: auto;
            width: auto;
            max-width: 100%;
          }
        }
        
        .property-top-info {
          margin: 0;
          text-align: center;
          
          .property-name {
            white-space: normal;
          }
        }
        
        .property-detail-info {
          border-top: none;
        }
      }
    }
  }
  
  @media (max-width: $screen-xs) {
    .properties-wrapper {
      .property-item {
        .property-detail-info {
          padding: 0 15px;
          
          ul {
            li {
              strong {
                white-space: normal;
              }
            }
          }
          
          > div {
            float: none;
            padding: 15px 0 !important;
            width: auto !important;
            
            &:first-child {
              ul {
                li {
                  > strong {
                    position: relative;
                    display: inline;
                  }
                }
              }
            }
            
            &:nth-child(2) {
              border-top: solid 1px #c2c2c2;
              border-bottom: solid 1px #c2c2c2;
              
              &:before,
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.single-property {
  .property-title {
    position: relative;
    margin: 0 0 35px;
    background: #ebebeb;
    padding-right: 200px;
    
    h1 {
      color: #363636;
      font-size: 20px;
      line-height: 25px;
      text-transform: none;
      padding: 15px 15px 10px 20px;
      margin: 0;
    }
    
    .property-price {
      width: 200px;
      text-align: center;
      line-height: 35px;
      padding: 10px;
      color: $brand-primary;
      font-size: 22px;
      font-weight: 600;
      background: #cecece;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
  
  .property-border-info {
    border-top: solid 1px #c2c2c2;
    border-bottom: solid 1px #c2c2c2;
    margin: 30px 0;
    padding: 18px 0;
    
    .item-details {
      > div {
        display: inline-block;
        line-height: 29px;
        margin: 5px 20px 5px 0;
        color: $brand-primary;
        font-size: 15px;
        font-weight: 600;
        vertical-align: top;
        
        span {
          display: inline-block;
          
          &:before {
            display: inline-block;
            margin-right: 10px;
            height: 27px;
            content: "";
            font-size: 0;
            vertical-align: middle;
          }
          
          &.icon-home {
            &:before {
              background: url("../images/icon-toh.png") no-repeat center;
              width: 26px;
            }
          }
          
          &.icon-bed {
            &:before {
              background: url("../images/icon-bed.png") no-repeat center;
              width: 33px;
            }
          }
          
          &.icon-shower {
            &:before {
              background: url("../images/icon-shower.png") no-repeat center;
              width: 29px;
            }
          }
        }
      }
    }
    
    .button {
      background: #00a74d;

      &:hover {
        background: #007338;
      }
    }
  }
  
  .property-information {
    border-top: solid 1px #c2c2c2;
    padding-top: 25px;
    margin-top: 25px;
    
    #{$headings} {
      color: $brand-primary;
      text-transform: none;
    }
    
    dl {
      margin-bottom: 20px;
      @include clearfix;
      
      dt {
        width: 150px;
        float: left;
        padding: 5px 10px 5px 0;
        clear: left;
      }
      
      dd {
        padding: 5px 0 5px 130px;
        border-bottom: solid 1px #cfd0cf;
        min-height: 28px;
      }
    }
  }
  
  .images-top {
    margin: 0 0 20px;
    @include clearfix;
    
    .main-image {
      margin: 0 0 15px;
      
      .slide {
        &:focus,
        &:active,
        &:focus:active {
          outline: none;
        }
        
        img {
          width: 100%;
        }
      }
      
      .slide-image {
        background: no-repeat center top;
        background-size: contain;
      }
    }
    
    .small-images {
      margin: 0 -2px 0 -3px;
      
      .slide {
        float: left;
        width: 20%;
        padding: 0 3px;
        
        a {
          display: block;
        }
        
        img {
          cursor: pointer;
        }
        
        &:focus,
        &:active,
        &:focus:active {
          outline: none;
        }
      }
      
      .slide-image {
        background: no-repeat center;
        background-size: cover;
      }
    }
  }
  
  .property-images-row {
    @include grey-dots-gradient(#efefef, #ffffff, 0, .06);
    padding: 40px 0;
    
    .row {
      margin: 0 -10px;
      
      .image-box {
        width: 25%;
        float: left;
        padding: 10px;
        
        a {
          display: block;
          
          img {
            width: 100%;
          }
        }
        
        &:nth-child(4n+1) {
          clear: left;
        }
      }
    }
  }
  
  #property-map {
    background: #ebebeb;
    box-shadow: 1px 2px 3px rgba(0,0,0,.2);
    margin: 30px 0;
    height: 270px;
    
    .map-wrapper {
      width: 100%;
      height: 100%;
    }
  }
  
  @media (max-width: $screen-xs-max) {
    .property-title {
      padding-right: 150px;
      
      .property-price {
        width: 150px;
        font-size: 18px;
      }
    }
    
    .property-images-row {
      .row {
        .image-box {
          width: 33.333%;
          
          &:nth-child(4n+1) {
            clear: none;
          }
          
          &:nth-child(3n+1) {
            clear: left;
          }
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .property-images-row {
      .row {
        .image-box {
          width: 50%;
          
          &:nth-child(4n+1) {
            clear: both;
          }
          
          &:nth-child(3n+1) {
            clear: none;
          }
          
          &:nth-child(2n+1) {
            clear: both;
          }
        }
      }
    }
  }
}

.articles-title {
  margin-bottom: 30px;
}

.articles {
  border-top: solid 1px #cecece;
  
  article {
    padding: 20px 0;
    border-bottom: solid 1px #cecece;
  }
  
  .navigation {
    padding: 30px 0 0;
    font-weight: 600;
    font-size: 16px;
  }
}