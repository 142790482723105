body#tinymce {
  margin: 12px !important;
  background: #fff;
  border: none;
  
  img {
    background: linear-gradient(45deg,#eeeeee 25%,transparent 25%,transparent 75%,#eeeeee 75%,#eeeeee),
      linear-gradient(45deg,#eeeeee 25%,transparent 25%,transparent 75%,#eeeeee 75%,#eeeeee);
    background-position: 0 0,15px 15px;
    background-size: 30px 30px;
  }
}