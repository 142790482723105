@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,600italic);

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?iqd8ba');
  src:  url('../fonts/icomoon.eot?iqd8ba#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?iqd8ba') format('truetype'),
    url('../fonts/icomoon.woff?iqd8ba') format('woff'),
    url('../fonts/icomoon.svg?iqd8ba#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.custom-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bathtub-with-opened-shower:before {
  content: "\e900";
}
.icon-double-king-size-bed:before {
  content: "\e901";
}
.icon-price-tag:before {
  content: "\e902";
}
.icon-rest:before {
  content: "\e903";
}
.icon-search-home:before {
  content: "\e904";
}
.icon-searching-for-a-house:before {
  content: "\e905";
}
.icon-searching-for-houses-online:before {
  content: "\e906";
}
.icon-sleeping-bed-silhouette:before {
  content: "\e907";
}

body {
  border-top: solid 10px #000;
  
  #ajax-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100001;
    opacity: 0;
    top: 0;
    left: 0;
    visibility: hidden;
    
    .loading-wrapper {
      @include loading-overlay;
      display: block;
      
      > span {
        top: 40%;
        margin-top: 0;
      }
    }
    
    &.on {
      opacity: 1;
      visibility: visible;
    }
  }
  
  &.ajax-loading {
    #ajax-loader {
      opacity: 1;
      visibility: visible;
    }
  }
}

b, strong {
  font-weight: 600;
}

#wrapper {
  overflow: hidden;
  position: relative;
}

#{$headings} {
  text-transform: uppercase;
  
  &:first-child {
    margin-top: 0;
  }
  
  &:last-child {
    margin-bottom: 0;
  }
}

p {
  line-height: 1.6;
  
  &:last-child {
    margin-bottom: 0;
  }
}

#wrapper {
  .bx-wrapper {
    .bx-controls-direction {
      a {
        background: $brand-primary;
        border: solid 2px #fff;
        color: #fff;
        width: 36px;
        height: 36px;
        line-height: 32px;
        text-align: center;
        text-decoration: none;
        border-radius: 50%;
        text-indent: 0;
        opacity: .6;
        font-size: 0;
        transition: all .3s ease-in-out;

        &:before {
          font-family: FontAwesome;
          font-size: 28px;
        }

        &.bx-prev {
          &:before {
            content: "\f104";
          }
        }

        &.bx-next {
          &:before {
            content: "\f105";
          }
        }

        &:hover {
          opacity: 1;
        }
        
        .home & {
          margin-top: -80px;
        }
      }
    }
  }
  
  .slick-arrow {
    background: $brand-primary;
    border: solid 2px #fff;
    color: #fff;
    width: 36px;
    height: 36px;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    text-indent: 0;
    opacity: .6;
    position: absolute;
    top: 50%;
    font-size: 0;
    z-index: 20;
    outline: none;
    transition: all .3s ease-in-out;

    &:before {
      font-family: FontAwesome;
      font-size: 28px;
    }

    &.slick-prev {
      left: 20px;
      
      &:before {
        content: "\f104";
      }
    }

    &.slick-next {
      right: 20px;
      
      &:before {
        content: "\f105";
      }
    }

    &:hover {
      opacity: 1;
    }

    .home & {
      margin-top: -80px;
    }
  }
}

.grey-content-area {
  @include grey-dots-gradient(#e3e3e3, #eeeeee, .65, .06);
}

.featured-properties-row {
  padding: 40px 0;
  @include grey-dots-gradient(#eeeeee, #f5f5f5, 0, .06);
  
  > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 14px;
    display: block;
    content: "";
    font-size: 0;
    background: url("../images/fp-shadow.png") repeat-x left top;
  }
  
  .featured-properties {
    z-index: 5;
    margin: 0 8px;
    position: relative;
    
    .slides {
      .slick-arrow {
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 33px;
        text-align: center;
        border: solid 2px #fff;
        box-shadow: 1px 2px 3px rgba(0,0,0,.42);
        background: #5f5f5f;
        color: #fff;
        outline: none;
        font-size: 0;
        transition: background .3s ease-in-out;
        
        &:hover {
          background: $brand-primary;
        }
        
        &:before {
          display: block;
          width: 100%;
          height: 30px;
          line-height: 30px;
          margin-top: -15px;
          top: 50%;
          left: 0;
          font-size: 30px;
          font-family: FontAwesome;
        }
        
        &.slick-prev {
          left: -40px;
          
          &:before {
            content: $fa-var-angle-left;
          }
        }
        
        &.slick-next {
          right: -40px;
          
          &:before {
            content: $fa-var-angle-right;
          }
        }
      }
      
      .slide {
        float: left;
        width: 270px;
        padding: 3px 8px;
        
        .slide-content {
          background: #fff;
          box-shadow: 2px 2px 5px rgba(0,0,0,.35);
          border: solid 3px #fff;
          
          > div {
            padding: 0 0 60px;
            overflow: hidden;
            position: relative;
          }
          
          .image {
            position: relative;
            
            img {
              width: 100%;
            }
          }
          
          .property-info {
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;
            background: #fff;
            transition: bottom .3s ease-in-out;
            
            .property-name {
              font-size: 16px;
              line-height: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-transform: uppercase;
              height: 30px;
              padding: 5px;
            }
            
            .property-price-and-status {
              background: #ebebeb;
              height: 30px;
              @include clearfix;
              
              .property-status {
                float: left;
                background: #d7d7d7;
                min-width: 88px;
                padding: 5px;
                text-align: center;
                font-size: 16px;
                color: #505050;
                line-height: 20px;
              }
              
              .property-price {
                float: right;
                font-size: 18px;
                /*font-style: italic;*/
                color: $brand-green;
                line-height: 24px;
                padding: 3px 10px 0 0;
              }
            }
          }
          
          .item-details {
            margin: 0;
            height: 30px;
            overflow: hidden;
            padding: 5px 10px 2px;
            @include clearfix;
            
            > div {
              line-height: 23px;
              
              span {
                color: #393939;
                font-size: 13px;
                display: inline-block;
                vertical-align: top;
                
                &:before {
                  content: "";
                  font-size: 0;
                  display: inline-block;
                  vertical-align: top;
                  height: 23px;
                  line-height: 23px;
                  margin-right: 5px;
                }
                
                &.icon-home {
                  &:before {
                    width: 18px;
                    background: url("../images/icon-house-grey.png") no-repeat center;
                  }
                }
                
                &.icon-bed {
                  &:before {
                    width: 28px;
                    background: url("../images/icon-bed-grey.png") no-repeat center;
                  }
                }
                
                &.icon-shower {
                  &:before {
                    width: 24px;
                    background: url("../images/icon-shower-grey.png") no-repeat center;
                  }
                }
                
                & + span {
                  margin-left: 10px;
                }
              }
            }
          }
          
          &:hover {
            .property-info {
              bottom: 0;
            }
          }
        }
        
        .ghost-link {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
          cursor: pointer;
          text-decoration: none;
          font-size: 0;
        }
        
        &:focus {
          outline: none;
        }
        
        &:hover {
          outline: none;
          
          .slide-content {
            .overlay {
              top: 0;
              bottom: 0;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.testimonial-row {
  @include grey-dots-gradient(#e3e3e3, #e7e7e7, 0, .16);
  padding: 70px 0;
  
  blockquote {
    margin: auto;
    position: relative;
    max-width: 880px;
    text-align: center;
    font-size: 18px;
    line-height: 1.8333;
    padding: 0 50px;
    
    &:before,
    &:after {
      display: block;
      font-size: 80px;
      position: absolute;
      top: -40px;
    }
    
    &:before {
      content: "\201C";
      left: 0;
    }
    
    &:after {
      content: "\201D";
      right: 0;
    }
    
    * {
      line-height: 1.8333;
    }
    
    & + div {
      text-align: right;
      padding: 0 50px;
      font-style: italic;
      font-size: 16px;
      margin: auto;
      max-width: 880px;
    }
  }
  
  @media (max-width: $screen-xs-max) {
    blockquote {
      font-size: 16px;
      
      & + div {
        font-size: 16px;
      }
    }
  }
}

.padding-top-bottom {
  padding: 40px 0;
}

.content-row {
  margin: 0 -20px;
  
  #sidebar {
    width: 32.5%;
    padding: 0 20px;
    float: left;
    
    .widget {
      background: #fff;
      box-shadow: 1px 2px 3px rgba(0,0,0,.2);
      padding: 30px 40px;
      
      & + .widget {
        margin-top: 20px;
      }
      
      h3 {
        margin-bottom: 20px;
      }
      
      .textwidget {
        .fullwidth {
          margin: -30px -40px;
          box-shadow: 1px 2px 3px rgba(0,0,0,.2);
          
          a {
            display: block;
          }
          
          img {
            width: 100%;
          }
          
          &.border-image {
            img {
              border: solid 3px #fff;
            }
          }
          
          & + h3 {
            margin-top: 45px;
          }
        }
      }
    }
  }
  
  #content-column {
    width: 67.5%;
    padding: 0 20px;
    float: right;
  }
  
  @media (max-width: $screen-md-max) {
    #sidebar {
      .widget {
        padding: 20px;
        
        .textwidget {
          .fullwidth {
            margin: -20px -20px;
          }
        }
      }
    }
  }
  
  @media (max-width: $screen-xs-max) {
    #sidebar,
    #content-column {
      width: auto;
      float: none;
    }
    
    #sidebar {
      margin: 40px auto 0;
      max-width: 480px;
    }
  }
}

.calculator-box {
  table {
    width: 100%;
    
    td {
      padding: 5px 3px;
      vertical-align: middle;
      font-size: 14px;
      
      &:first-child {
        padding-left: 0;
        font-size: 16px;
      }
      
      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
  
  .form-control.error {
    border-color: $brand-warning;
  }
  
  #{$inputs}, select {
    width: 100%;
    border: solid 1px #ccc;
    border-radius: 10px;
    padding: 8px 15px;
    font-size: 14px;
    font-family: $font-family-base;
    outline: none;
    background: #fff;
  }
}

.pdf-email-report {
  color: #b7b7b7;
  border-top: solid 1px #b7b7b7;
  border-bottom: solid 1px #b7b7b7;
  padding: 5px 0;
  margin: 0 0 10px;
  
  label {
    color: $brand-primary;
    margin: 0;
  }
  
  #show-pdf-email {
    display: none;
    margin: 5px 0 0;
  }
}

.waiting {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 30px;
  color: $brand-primary;
  display: none;
  background: rgba(#fff, .8);
  z-index: 201;
  
  &.on {
    display: block;
  }
}

.mortgage-table-wrapper {
  max-height: 300px;
  overflow: auto;
  margin: 0 0 30px;
}

.display-table {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  
  .display-table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

.white-popup-block {
  background: #fff;
  padding: 20px 30px;
  text-align: left;
  max-width: 860px;
  margin: 40px auto;
  position: relative;
  border-radius: 15px;
}

.table-bordered {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #ddd;
  border-collapse: collapse;
  table-layout: fixed;
  
  td, th {
    border: 1px solid #ddd;
    padding: 5px;
  }
  
  th {
    font-weight: 600
  }
}

.table-striped {
  tr {
    &:nth-of-type(odd) {
      background: #f9f9f9;
    }
  }
}

//
// Tooltips
// --------------------------------------------------


// Base class
.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text;
  font-size: $font-size-small;

  @include opacity(0);

  &.in     { @include opacity($tooltip-opacity); }
  &.top    { margin-top:  -3px; padding: $tooltip-arrow-width 0; }
  &.right  { margin-left:  3px; padding: 0 $tooltip-arrow-width; }
  &.bottom { margin-top:   3px; padding: $tooltip-arrow-width 0; }
  &.left   { margin-left: -3px; padding: 0 $tooltip-arrow-width; }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: 3px 8px;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  border-radius: $border-radius-base;
}

// Arrows
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
.tooltip {
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.top-left .tooltip-arrow {
    bottom: 0;
    right: $tooltip-arrow-width;
    margin-bottom: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.top-right .tooltip-arrow {
    bottom: 0;
    left: $tooltip-arrow-width;
    margin-bottom: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
    border-right-color: $tooltip-arrow-color;
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-left-color: $tooltip-arrow-color;
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
  &.bottom-left .tooltip-arrow {
    top: 0;
    right: $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
  &.bottom-right .tooltip-arrow {
    top: 0;
    left: $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }
}


.list-pagination {
  background: rgba(#cecece, .4);
  position: relative;
  text-align: center;
  font-size: 0;
  margin-top: 30px;
  
  &:before {
    display: block;
    content: "";
    font-size: 0;
    position: absolute;
    top: -9px;
    left: 0;
    right: 0;
    border-top: solid 1px #cecece;
  }
  
  > * {
    display: inline-block;
    vertical-align: top;
    margin: 0 4px;
    background: #cecece;
    min-width: 52px;
    height: 46px;
    line-height: 26px;
    padding: 10px 15px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: $brand-primary;
    text-decoration: none;
    text-transform: uppercase;
    transition:  all .3s ease-in-out;
  }
  
  
  .current, a:hover {
    background: $brand-primary;
    color: #fff;
    text-decoration: none;
  }
}

.show-more-photos {
  margin: 15px 0 0;
}


#map-filters {
  position: absolute;
  z-index: 20;
  right: 0;
  bottom: 115px;
  left: 50%;
  margin-left: -50%;
  width: 100%;
  
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
    margin-left: -($container-sm/2);
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
    margin-left: -($container-md/2);
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
    margin-left: -($container-lg/2);
  }
  
  .filter-box {
    box-shadow: 2px 2px 5px rgba(0,0,0,.44);
    background: rgba(255, 255, 255, .85);
  }
  
  #map-filters-form {
    display: block;
  }
  
  #new-search {
    display: none;
    
    a {
      display: block;
      text-align: center;
      padding: 10px;
      color: #000;
      font-size: 16px;
      text-decoration: none;
      transition: all .3s ease-in-out;
      
      &:hover {
        background: #fff;
      }
      
      .custom-icon {
        font-size: 150%;
        vertical-align: middle;
      }
    }
  }
  
  .filters-top {
    background: rgba(0, 0, 0, .85);
    padding: 20px 15px 15px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    
    .filters-top-title {
      font-size: 22px;
    }
  }
  
  .filter-fields {
    padding: 35px 20px;
    max-width: 990px;
    margin: auto;
    color: #000;
    
    .filter-row {
      margin: 0 -15px;
      @include clearfix;
      
      .filter-column {
        width: 25%;
        float: left;
        padding: 0 15px;
      }
      
      .filter-column-large {
        width: calc(100% - 300px);
        float: left;
        padding: 0 15px;
      }
      
      .filter-column-button {
        width: 300px;
        float: left;
        padding: 0 15px;
        
        button {
          width: 100%;
          height: 40px;
          border-radius: 15px;
          background: $brand-primary;
          color: #fff;
          border: solid 1px #000;
          
          &:hover {
            background: #000;
            color: #fff;
          }
        }
      }
    }
    
    .filter-row-separator {
      border-top: solid 1px #acacac;
      margin: 20px auto;
      font-size: 0;
      height: 1px;
    }
    
    .filter-icon {
      text-align: center;
      height: 80px;
      line-height: 80px;
      font-size: 80px;
      margin-bottom: 20px;
      color: #000;
    }
    
    input[type="text"], select {
      appearance: none;
      background: transparent;
      border: solid 1px #000;
      font-size: 16px;
      height: 40px;
      padding: 5px 15px;
      line-height: 28px;
      color: #000;
      border-radius: 15px;
      width: 100%;
      outline: none;
      
      @include placeholder {
        color: #000 !important;
        opacity: .95;
        font-style: italic;
      }
    }
    
    select {
      background: url("../images/select-arrow.png") no-repeat right center;
    }
    
    .form-group {
      input[type="text"].error, select.error {
        border-color: $brand-primary;
        color: red;

        @include placeholder {
          color: $brand-primary;
          opacity: .95;
        }
      }
    }
  }
  
  @media (max-width: 640px) {
    .filter-fields {
      padding: 20px;
      
      .filter-row {
        .filter-column {
          margin-bottom: 15px;
          width: 50%;
          
          &:nth-child(2n+1) {
            clear: left;
          }
        }
        
        .filter-column-large {
          width: 100%;
          margin-bottom: 15px;
        }
        
        .filter-column-button {
          width: 100%;
        }
      }
      
      .filter-icon {
        display: none;
      }
      
      .filter-row-separator {
        margin-top: 5px;
      }
    }
  }
}

#map-zoom-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  font-size: 20px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
}

body.map-fullscreen {
  #properties-map {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
  }
  
  #map-filters {
    bottom: 20px;
  }
}

.animation-pulsate {
  -webkit-animation: anim-pulsate 2s infinite;
          animation: anim-pulsate 2s infinite;
}

@keyframes anim-pulsate {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

.property-popup {
  line-height: 1.3333;
  max-width: 270px;
  min-height: 70px;
  position: relative;
  padding: 10px 10px 10px 90px;
  
  > div {
    & + div {
      margin-top: 3px;
    }
  }
  
  .image {
    margin: 0;
    width: 70px;
    height: 70px;
    overflow: hidden;
    position: absolute;
    top: 10px;
    left: 10px;
    
    .fa {
      position: absolute;
      width: 36px;
      height: 36px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -18px;
      color: $brand-primary;
      opacity: 1;
      visibility: visible;
      transition: all .5s ease-in-out;
      
      &.not-visible {
        opacity: 0;
        visibility: hidden;
      }
    }
    
    &:empty {
      display: none;
    }
  }
  
  .title {
    font-weight: 600;
  }
  
  .price {
    color: $brand-primary;
  }
  
  .vt-available {
    color: $brand-primary;
    
    &:empty {
      display: none;
    }
  }
  
  .pre-title {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    text-align: center;
    font-weight: 600;
    transition: none;
  }
  
  &.pre-info {
    > div {
      opacity: 0;
      visibility: hidden;
    }
    
    .pre-title {
      opacity: 1;
      visibility: visible;
      
      .fa {
        color: $brand-primary;
        display: block;
        margin-top: 15px;
      }
    }
  }
  
  & + .link {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }
  
  & + .property-popup {
    margin-top: 5px;
  }
}

.leaflet-control-layers-toggle {
	background-image: url(../images/layers.png);
}

.leaflet-container a.leaflet-popup-close-button {
  z-index: 20;
}

.leaflet-popup-content-wrapper {
  transition: box-shadow .3s ease-in-out;
  
  &:hover {
    box-shadow: 0 0 10px rgba($brand-primary, .5);
  }
  
  .leaflet-popup-content {
    margin: 0;
    position: relative;
    
    .link {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-decoration: none;
    }
  }
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

/*.leaflet-bottom.leaflet-left,
.leaflet-top.leaflet-left {
  display: none;
}*/

.leaflet-container .img-responsive {
  max-width: 100% !important;
}

.posts-pagination {
  clear: both;
  text-align: center;
  padding: 12px;
  border-top: solid 1px #898989;
  border-bottom: solid 1px #898989;
  margin: 30px 0;
  position: relative;
  top: 30px;
  
  &:empty {
    display: none;
  }

  > * {
    display: inline-block;
    vertical-align: top;
    padding: 5px;
    line-height: 24px;
    min-width: 34px;
    text-align: center;
    background: #898989;
    color: #fff;
    margin: 1px;
    text-decoration: none !important;

    &.current {
      background: $brand-primary;
    }
    
    &.disabled {
      cursor: not-allowed;
      opacity: .5;
      
      &:hover {
        color: #fff;
      }
    }
    
    &:focus,
    &:active {
      color: #fff;
    }
  }
  
  a:not(.disabled) {
    &:hover {
      background: $brand-primary;
      color: #fff;
      text-decoration: none;
    }
  }
}

.leaflet-verticalcenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-top: 10px;
}

.leaflet-verticalcenter .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-top.leaflet-left .leaflet-control-zoom {
  display: none;
}

.leaflet-top.leaflet-right .leaflet-control-layers-toggle {
  display: none;
}

.text-big {
  font-size: 150%;
}

.intro-text {
  padding: 30px 0 0;
}