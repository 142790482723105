$brand-primary:                 #9d0a0e;
$brand-green:                   #00a651;
$brand-warning:                 #f19100;
$text-color:                    #464646;
$link-color:                    $brand-primary;
$link-hover-color:              darken($link-color, 10%);
$body-bg:                       #fff;
$blockquote-border-color:       inherit;
$blockquote-small-color:        inherit;
$text-muted:                    lighten($brand-primary, 10%);
$hr-border:                     lighten($brand-primary, 10%);


$link-decoration:               none;
$link-hover-decoration:         underline;


$font-family-sans-serif:        'Open Sans', sans-serif;
$font-family-serif:             Georgia, "Times New Roman", Times, serif;
$font-family-monospace:         Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:              $font-family-sans-serif;
$font-size-base:                14px;
$font-size-large:               ceil(($font-size-base * 1.25));
$font-size-small:               ceil(($font-size-base * 0.85));
$blockquote-font-size:          ($font-size-base * 1.25);
$line-height-base:              1.6;
$line-height-computed:          floor(($font-size-base * $line-height-base));


$headings-font-family:          inherit;
$headings-font-weight:          600;
$headings-line-height:          1.2;
$headings-color:                inherit;
$headings-small-color:          inherit;
$headings: "h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6";

$inputs:                        "input[type='text'], input[type='email'], input[type='password'], input[type='url'], input[type='tel'], input[type='number']";
$buttons:                       "input[type='submit'], input.button, button";

$font-size-h1:                  30px;
$font-size-h2:                  24px;
$font-size-h3:                  20px;
$font-size-h4:                  16px;
$font-size-h5:                  14px;
$font-size-h6:                  12px;


$border-radius-base:            5px;
$border-radius-large:           10px;


$thumbnail-padding:             4px;
$thumbnail-bg:                  $body-bg;
$thumbnail-border:              #ddd;
$thumbnail-border-radius:       $border-radius-base;
$thumbnail-caption-color:       $text-color;
$thumbnail-caption-padding:     9px;


$dl-horizontal-offset:          180px;

$grid-gutter-width:             30px;

$screen-xs:                     640px;
$screen-xs-min:                 $screen-xs;
$screen-phone:                  $screen-xs-min;
$screen-sm:                     768px;
$screen-sm-min:                 $screen-sm;
$screen-tablet:                 $screen-sm-min;
$screen-md:                     992px;
$screen-md-min:                 $screen-md;
$screen-desktop:                $screen-md-min;
$screen-lg:                     1300px;
$screen-lg-min:                 $screen-lg;
$screen-lg-desktop:             $screen-lg-min;
$screen-xs-max:                 ($screen-sm-min - 1);
$screen-sm-max:                 ($screen-md-min - 1);
$screen-md-max:                 ($screen-lg-min - 1);
$container-tablet:              (720px + $grid-gutter-width);
$container-sm:                  $container-tablet;
$container-desktop:             (940px + $grid-gutter-width);
$container-md:                  $container-desktop;
$container-large-desktop:       (1080px + $grid-gutter-width);
$container-lg:                  $container-large-desktop;

$grid-columns:                  12;
$grid-float-breakpoint:         $screen-sm-min;
$grid-float-breakpoint-max:     ($grid-float-breakpoint - 1);

$zindex-tooltip:              1070;
$tooltip-max-width:           200px;
$tooltip-color:               #fff;
$tooltip-bg:                  #000;
$tooltip-opacity:             .9;
$tooltip-arrow-width:         5px;
$tooltip-arrow-color:         $tooltip-bg;

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;

$alert-padding:               15px !default;
$alert-border-radius:         $border-radius-base !default;
$alert-link-font-weight:      bold !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-text:           $state-danger-text !default;
$alert-danger-border:         $state-danger-border !default;