.gform_wrapper.inline-form_wrapper {
  margin: 0;
  width: 100%;
  max-width: 100%;
  
  .inline-form {
    .gform_fields {
      li.gfield {
        .gfield_label {
          display: none;
        }

        .ginput_container {
          margin: 0;
          border-bottom: solid 1px #e1e1e1;

          #{$inputs}, textarea {
            width: 100%;
            outline: none;
            background: transparent;
            border: none;
            width: 100%;
            font-size: 14px;
            line-height: 20px;
            padding: 5px 0;
            font-family: $font-family-base;
            color: $brand-primary;

            @include placeholder {
              color: $brand-primary;
              opacity: .85;
            }
          }

          textarea {
            height: 70px;
          }
        }
        
        &.gfield_error {
          margin: 0 !important;
          padding: 0 !important;
          width: 100%;
        }
      }
    }

    .gform_footer {
      margin: 0;
      padding: 4px 0;
    }
    
    #{$buttons} {
      width: 100%;
      color: $brand-primary;
      background: #cecece;
      font-size: 18px;
      
      &:hover,
      &:focus {
        color: #fff;
        background: $brand-primary;
      }
    }
  }
  
  div.validation_error {
    padding: 10px 0;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.search-filter-wrapper {
  position: relative;
  border-bottom: solid 1px #cecece;
  padding-bottom: 9px;
  margin-bottom: 25px;
  overflow: hidden;

  .search-filter {
    background: rgba(#cecece, .4);
    min-height: 46px;
    text-align: left;
    position: relative;

    .filter-button-toggle {
      width: 360px;
      margin: auto;
      text-align: center;
      position: relative;
      z-index: 11;

      a {
        text-align: center;
        background: #cecece;
        color: $brand-primary;
        font-size: 18px;
        font-family: $font-family-base;
        line-height: 30px;
        padding: 8px 10px;
        display: block;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        height: 46px;
        
        &:after {
          vertical-align: top;
          display: inline-block;
          width: 30px;
          height: 30px;
          background: url("../images/icon-house.png") no-repeat center top;
          content: "";
          font-size: 0;
          margin-left: 10px;
        }
      }
    }

    .search-filter-fields {
      background: rgba(#cecece, .9);
      display: none;
      padding: 40px 40px 0;
      color: #fff;
      z-index: 10;
      font-size: 14px;
      font-family: $font-family-base;
      
      .form-group {
        margin: 0 0 15px;
        
        label {
          color: $brand-primary;
          display: block;
          cursor: pointer;
          margin: 0 0 7px;
          font-weight: normal;
          text-align: left;
        }
        
        .form-control {
          background: #fff;
          border: none;
          font-size: 14px;
          font-weight: normal;
          font-family: $font-family-base;
          color: $brand-primary;
          padding: 5px 13px;
          line-height: 20px;
          border-radius: 0;
          width: 100%;
          text-align: left;
          border: none;
          outline: none;
        }
      }
      
      .slider {
        &.slider-horizontal {
          width: 100%;
          height: 6px;
          margin-top: 20px;
          
          .slider-track {
            height: 2px;
            background: rgba(255, 255, 255, .5);
            top: 0;
          }
          
          .slider-handle.round {
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background: #fff;
            box-shadow: 2px 1px 5px rgba(0,0,0,.75);
            margin: -7px 0 0 -8px;
          }
          
          .slider-selection {
            background: #fff;
            top: -1px;
            height: 4px;
          }
          
          .tooltip {
            z-index: 9;
            margin-top: -41px;
            padding: 0;
            
            .tooltip-inner {
              color: #fff;
              background-color: $brand-primary;
              border: solid 1px #9da8bc;
              font-size: 12px;
              font-family: $font-family-base;
              line-height: 16px;
              padding: 2px 15px;
              border-radius: 5px;
            }
            
            .tooltip-arrow {
              border: none !important;
              width: 14px;
              height: 9px;
              background: url("../images/tooltip-arrow.png") no-repeat center top;
              margin: 0 0 0 -7px;
              bottom: -8px;
            }
          }
        }
      }
      
      .form-buttons {
        border-bottom: solid 1px #cecece;
        background: rgba(255, 255, 255, .51);
        margin: 0 -40px;
        font-size: 0;
        text-align: center;
        
        a {
          display: inline-block;
          vertical-align: top;
          line-height: 28px;
          padding: 5px 40px;
          background: rgba(255, 255, 255, .2);
          text-align: center;
          font-size: 14px;
          font-family: $font-family-base;
          color: #555555;
          text-decoration: none;
          text-transform: uppercase;
          transition: all .3s ease-out;
          border-left: solid 1px #cecece;
          border-right: solid 1px #cecece;
          margin-right: -1px;
          
          &:hover {
            background: rgba(255, 255, 255, .7);
            color: $brand-primary;
          }
          
          &.button-apply {
            background: rgba(255, 255, 255, .6);
            color: $brand-primary;
            
            &:hover {
              background: rgba(255, 255, 255, .9);
            }
          }
        }
      }
      
      @media (max-width: $screen-xs-max) {
        padding: 40px 20px 0;
        
        .form-buttons {
          margin: 0 -20px;
          
          a {
            padding: 5px 25px;
          }
        }
      }
    }
  }
  
  .form-loading {
    @include loading-overlay;
  }
}

.twitter-typeahead {
  display: block;
  width: 100%;
}

.tt-menu {
  width: 100%;
  background-color: #fff;
  border-top: solid 1px $brand-primary;
  z-index: 10;
  color: $text-color;
}

.tt-suggestion {
  padding: 3px 13px;
  font-size: 14px;
  line-height: 20px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion p {
  margin: 0;
}

.tt-suggestion .tt-highlight {
  font-weight: normal;
  font-family: $font-family-base;
  color: #333;
}

#group-city {
  position: relative;
  z-index: 10;
}

.property-modal {
  .modal-content {
    .modal-header {
      h3 {
        text-transform: none;
        border-bottom: solid 1px #ccc;
        padding-bottom: 15px;
        margin-bottom: 25px;
      }
    }
    
    .modal-body {
      h3 {
        font-size: 22px;
      }
      
      .gform_wrapper {
        .gform_body {
          margin: 0 -10px;
          
          ul {
            li.gfield {
              padding: 0 10px;
              
              &.field-half {
                width: 50%;
                float: left;
                clear: none;
                
                @media (max-width: $screen-xs-max) {
                  width: 100%;
                  float: none;
                }
              }
              
              &.clear-left {
                clear: left;
              }
              
              div.ginput_container.ginput_complex {
                margin: 0 -10px !important;
                width: auto !important;
                max-width: 150% !important;
                
                > span {
                  width: 50%;
                  padding: 0 10px;
                  margin: 0;
                  float: left;
                  
                  @media (max-width: $screen-xs-max) {
                    width: 100%;
                    float: none;
                  }
                }
              }
              
              #{$inputs}, select, textarea {
                border: solid 1px #ccc;
                width: 100%;
                max-width: 100%;
                border-radius: 5px;
                padding: 7px 10px;
                font-family: $font-family-base;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}