.button {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: none;
  white-space: nowrap;
  padding: 5px 25px;
  font-size: 18px;
  font-family: $font-family-base;
  font-weight: normal;
  line-height: 28px;
  border-radius: 0;
  user-select: none;
  outline: none;
  /*background: $brand-primary;*/
  background: $brand-green;
  color: #fff;
  transition: all .3s ease-in-out;
  
  &:hover,
  &:focus {
    background: darken($brand-green, 10%);
    color: #fff;
    text-decoration: none;
  }
  
  &.button-block {
    display: block;
    width: 100%;
  }
  
  &.button-grey {
    color: $brand-primary;
    background: #cecece;

    &:hover,
    &:focus {
      color: #fff;
      background: $brand-primary;
    }
  }
  
  &.button-small {
    font-size: 14px;
    line-height: 18px;
  }
  
  &.arrow-left {
    &:before {
      font-family: 'FontAwesome';
      content: $fa-var-angle-left;
      margin-right: 10px;
    }
  }
  
  &.arrow-right {
    &:after {
      font-family: 'FontAwesome';
      content: $fa-var-angle-right;
      margin-left: 10px;
    }
  }
}