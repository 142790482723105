header#header {
  position: absolute;
  top: 177px;
  left: 0;
  width: 100%;
  z-index: 200;
  background: rgba(#171717, .9);
  box-shadow: 2px 2px 5px rgba(0,0,0,.44);
  border-top: solid 1px #d6d5d5;
  border-bottom: solid 1px #d6d5d5;
  
  #logo {
    float: left;
    height: 97px;
    max-width: 265px;
    padding: 9px 0 0;
    
    a {
      display: block;
    }
  }
  
  @media (min-width: $screen-sm-min) {
    text-align: center;
    
    #navigation {
      display: block !important;
      @include clearfix;
    }
    
    .social-links {
      float: right;
      padding: 30px 0;
      line-height: 37px;
      
      a {
        display: inline-block;
        vertical-align: top;
        color: #fff;
        font-size: 20px;
        
        & + a {
          margin-left: 5px;
        }
      }
    }
    
    .nav-primary {
      float: left;
      height: 97px;
      overflow: visible;
      
      .nav {
        list-style: none;
        display: inline-block;
        margin: 0;
        padding: 0;
        
        > li {
          display: inline-block;
          float: left;
          position: relative;
          height: 101px;
          
          > a {
            display: inline-block;
            float: left;
            line-height: 37px;
            padding: 30px 0;
            font-size: 18px;
            text-decoration: none;
            text-transform: uppercase;
            color: #cccccc;
            transition: color .3s ease-in-out;
            
            &:after {
              font-family: FontAwesome;
              content: "\f105";
              margin-left: 5px;
              display: inline-block;
              width: 10px;
              text-align: right;
            }
            
            &:hover {
              color: #fff;
            }
          }
          
          .sub-menu {
            list-style: none;
            background: rgba(23, 23, 23, .95);
            box-shadow: 2px 2px 5px rgba(0, 0, 0, .44);
            border: solid 1px rgba(#d6d5d5, .75);
            margin: -20px 0 0;
            padding: 10px 15px;
            position: absolute;
            top: 100%;
            left: -15px;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease-in-out;
            
            li {
              text-align: left;
              display: block;
              
              a {
                display: block;
                color: #ccc;
                text-decoration: none;
                text-transform: uppercase;
                padding: 10px 0;
                white-space: nowrap;
                text-decoration: none;
                font-size: 16px;
                line-height: 20px;
                
                &:hover {
                  color: #fff;
                  text-decoration: none;
                }
              }
              
              & + li {
                border-top: solid 1px rgba(#d6d5d5, .75);
              }
              
              &.active {
                > a {
                  color: #fff;
                }
              }
            }
          }
          
          &.active {
            > a {
              color: #fff;
              
              &:after {
                content: "\f107";
              }
            }
          }
          
          & + li {
            margin-left: 20px;
          }
        }
        
        li {
          &:hover {
            > .sub-menu {
              opacity: 1;
              visibility: visible;
              margin-top: -10px;
            }
          }
        }
      }
    }
    
    #menu-toggle {
      display: none;
    }
    
    @media (max-width: $screen-sm-max) {
      top: 190px;
      
      #logo {
        height: 83px;
        max-width: 230px;
      }
      
      .social-links {
        padding-top: 24px;
        padding-bottom: 23px;
      }
      
      .nav-primary {
        height: 83px;
        
        .nav {
          > li {
            > a {
              font-size: 15px;
              padding-top: 24px;
              padding-bottom: 23px;
              
              &:after {
                margin-left: 0;
              }
            }
            
            & + li {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: $screen-xs-max) {
    top: 157px;
    
    body.home & {
      top: 300px !important;
    }
    
    #logo {
      height: 76px;
      padding-top: 10px;
      max-width: 200px;
    }
    
    #menu-toggle {
      display: block;
      float: right;
      margin: 18px 0 10px 0;
      font-size: 30px;
      color: #fff;
    }
    
    #navigation {
      display: none;
      clear: both;
    }
    
    .social-links {
      padding: 0 0 20px;
      
      a {
        display: inline-block;
        vertical-align: top;
        margin: 0 5px 5px 0;
        color: #fff;
        font-size: 24px;
      }
    }
    
    .nav-primary {
      height: auto;
      
      .nav {
        border-top: solid 1px rgba(255, 255, 255, .66);
        display: block;
        margin: 0;
        padding: 5px 5px 10px;
        
        > li {
          display: block;
          
          > a {
            display: block;
            text-decoration: none;
            font-size: 20px;
            padding: 5px 0;
            text-decoration: none;
            text-transform: uppercase;
            color: #cccccc;
            transition: all .3s ease-in-out;
            
            &:after {
              font-family: FontAwesome;
              content: "\f105";
              margin-left: 5px;
              display: inline-block;
              width: 10px;
              text-align: right;
            }
            
            &:hover {
              color: #fff;
            }
          }
          
          .sub-menu {
            list-style: none;
            margin: 0;
            padding: 0 0 0 20px;
            
            a {
              display: block;
              text-decoration: none;
              color: #ccc;
              font-size: 16px;
              text-transform: uppercase;
              
              &:hover {
                color: #fff;
                text-decoration: none;
              }
            }
            
            li {
              &.active {
                > a {
                  color: #fff;
                }
              }
            }
          }
          
          &.active {
            > a {
              color: #fff;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: $screen-xs) {
    top: 122px;
  }
  
  body.home & {
    top: 441px;
  }
  
  body.page-template-template-properties & {
    top: 541px !important;
  }
  
  body.single-property & {
    /*top: 217px !important;*/
  }
  
  body.single-property.with-virtual-tour & {
    top: 545px !important;
  }
  
  body.fixed-nav #wrapper & {
    position: fixed;
    top: 0 !important;
  }
  
  body.admin-bar.fixed-nav #wrapper & {
    position: fixed;
    top: 32px !important;
  }
}

#page-header {
  #banner {
    height: 275px;
    background: url("../images/banner-default.jpg") no-repeat center;
    background-size: cover;
  }
  
  .virtual-tour-wrapper {
    margin-bottom: 99px;
    
    iframe {
      vertical-align: top;
    }
    
    body.fixed-nav & {
      margin-bottom: 0;
    }
  }
  
  @media (max-width: $screen-xs-max) {
    #banner {
      height: 235px;
    }
  }
  
  @media (max-width: $screen-xs) {
    #banner {
      height: 200px;
    }
  }
}