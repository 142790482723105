footer#footer {
  background: #282828 url("../images/footer-bg.jpg") repeat-x center bottom;
  color: #fff;
  position: relative;
  padding: 40px 0;
  
  &:before {
    display: block;
    content: "";
    font-size: 0;
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    height: 18px;
    background: url("../images/footer-shadow.png") repeat-x left top;
    border-top: solid 1px rgba(255, 255, 255, .77);
  }
  
  #footer-main {
    background: url("../images/footer-shadow-top.png") repeat-y center;
    position: relative;
    padding: 15px 0;
    
    .footer-column {
      width: 23%;
      padding: ($grid-gutter-width/2);
      float: left;
      
      &.footer-column-1 {
        width: 31%;
      }
    }
    
    .latest-posts {
      .item {
        text-transform: uppercase;
        
        a {
          display: block;
          
          time {
            display: block;
            color: $brand-primary;
            margin-top: 3px;
            text-transform: none;
          }
          
          &:hover {
            text-decoration: none;
            
            time {
              color: #fff;
            }
          }
        }
        
        & + .item {
          margin-top: 10px;
        }
      }
    }
  }
  
  #footer-bottom {
    background: url("../images/footer-shadow-bottom.png") repeat-y center;
    padding: 15px 0;
    position: relative;
    
    .website-by {
      position: absolute;
      left: ($grid-gutter-width/2);
      top: 50%;
      line-height: 26px;
      margin-top: -13px;
      
      .img-responsive {
        display: inline-block;
        margin-left: 5px;
        vertical-align: top;
      }
    }
    
    .social-links {
      position: absolute;
      top: 50%;
      line-height: 30px;
      font-size: 30px;
      right: ($grid-gutter-width/2);
      margin-top: -15px;
      
      a {
        display: inline-block;
        line-height: 30px;
        transition: all .3s ease-in-out;
        
        &:hover {
          color: lighten($brand-primary, 15%);
        }
      }
    }
    
    .copyright-text {
      text-align: center;
      padding: 3px 200px;
    }
  }
  
  #footer-main:before,
  #footer-bottom:before,
  #footer-bottom:after {
    display: block;
    position: absolute;
    content: "";
    font-size: 0;
    height: 3px;
    width: 100%;
    left: 0;
    background: url("../images/footer-line.png") no-repeat center;
    top: 0;
  }
  
  #footer-bottom:after {
    top: auto;
    bottom: 0;
  }
  
  a {
    color: #fff;
  }
  
  @media (max-width: $screen-xs-max) {
    padding: 20px 0;
    
    #footer-main {
      .footer-column {
        width: auto !important;
        float: none;
      }
    }
    
    #footer-bottom {
      .website-by,
      .social-links {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        margin: 0;
        text-align: center;
      }
      
      .copyright-text {
        padding: 10px 0;
      }
    }
  }
}